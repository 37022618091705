// From: https://www.folhoffer.eu/kuerzel-fuer-textilfasern/ct-1550.html
const i18n = {
  "de-DE": {
    "mc_edit": {
       "t_N_status_ok": "Material ist OK oder die automatische Korrektur konnte angewandt werden.",
       "t_N_status_edit": "Irgendeine Materialbeschreibung scheint noch nicht im richtigen Format zu sein.",
       "t_N_status_error": "Materialzusammensetzung deckt sich nicht mit den allgemeinen Materialkonventionen<br><strong>Manuelle Anpassung erforderlich!</strong>",
       "t_N_from_db": "aus der Datenbank",
       "t_N_auto_correct": "Analyse:",
       "t_N_correct_manually": "manuell korrigieren:",
       "t_N_btn_sendMc": "Übernehmen",
       "t_N_formatDesc": "Materialkennzeichnungen müssen in das einheitliche Format gebracht werden. <em>Einzelne Materialien</em> sollten daher eine einheitliche Form haben.<br><strong><abbr title=\"Obermaterial, Innenfutter, ...\">Material:</abbr> xy%<sup>*</sup> <abbr title=\"Baumwolle, Viskose, ...\">Material 1</abbr>, xy%<sup>*</sup> <abbr title=\"..., Polyester, Elasthan etc\">Material 2</abbr> usw</strong>",
       "t_N_footnotes": "<hr><small><div><sup>*</sup> Die Summe der Prozentangaben muss 100% ergeben!</div></small>",
       "t_N_hint_part_ok": "Dieser Teil ist OK.",
       "t_N_hint_part_edit": "Dieser Teil muß überarbeitet werden. s.u."
    }
  }
};
export let global_mc_i18n = i18n;



// From: https://www.folhoffer.eu/kuerzel-fuer-textilfasern/ct-1550.html
const list = {
  "materials": [
    { key: "CA",     mapping: [ "Acetat" ] },
    { key: "AL",     mapping: [ "Alfagras (Esparto)" ] },
    { key: "ALG",    mapping: [ "Alginat" ] },
    { key: "WP",     mapping: [ "Alpaka" ] },
    { key: "WA",     mapping: [ "Angora" ] },
    { key: "AR",     mapping: [ "Aramid" ] },
    { key: "AS",     mapping: [ "Asbest" ] },
    { key: "CO",     mapping: [ "Baumwolle", "Baumwolle (cotton)" ] },
    { key: "CUP",    mapping: [ "Cupro" ] },
    { key: "EL",     mapping: [ "Elasthan" ] },
    { key: "ED",     mapping: [ "Elastodien" ] },
    { key: "FI",     mapping: [ "Fique" ] },
    { key: "LI",     mapping: [ "Flachs Leinen" ] },
    { key: "PTFE",   mapping: [ "Fluoro" ] },
    { key: "GF",     mapping: [ "Glas" ] },
    { key: "WU",     mapping: [ "Guanako" ] },
    { key: "LA",     mapping: [ "Gummi" ] },
    { key: "HA",     mapping: [ "Hanf" ] },
    { key: "HE",     mapping: [ "Henequen" ] },
    { key: "JU",     mapping: [ "Jute" ] },
    { key: "WK",     mapping: [ "Kamel" ] },
    { key: "WN",     mapping: [ "Kanin" ] },
    { key: "KP",     mapping: [ "Kapok" ] },
    { key: "WS",     mapping: [ "Kaschmir" ] },
    { key: "KE",     mapping: [ "Kenaf" ] },
    { key: "CF",     mapping: [ "Kohlenstoff" ] },
    { key: "CC",     mapping: [ "Kokos" ] },
    { key: "WL",     mapping: [ "Lama" ] },
    { key: "AB",     mapping: [ "Manila (Abaca)" ] },
    { key: "MTF",    mapping: [ "Metall" ] },
    { key: "MAC",    mapping: [ "Modacryl" ] },
    { key: "CMD",    mapping: [ "Modal" ] },
    { key: "WM",     mapping: [ "Mohair" ] },
    { key: "NF",     mapping: [ "Phormium (Neuseelandfaser)" ] },
    { key: "PP",     mapping: [ "Polspropylen" ] },
    { key: "PAN",    mapping: [ "Polyacryl" ] },
    { key: "PA",     mapping: [ "Polyamid" ] },
    { key: "PES",    mapping: [ "Polyester" ] },
    { key: "PE",     mapping: [ "Polyethylen" ] },
    { key: "PP",     mapping: [ "Polypropylen" ] },
    { key: "PVAL",   mapping: [ "Polyvinylalkohol" ] },
    { key: "CLF",    mapping: [ "Polyvinylchlorid", "Polyvinylidenchlorid" ] },
    { key: "RA",     mapping: [ "Ramie" ] },
    { key: "HR",     mapping: [ "Rinderhaar" ] },
    { key: "JS",     mapping: [ "Rosella" ] },
    { key: "HS",     mapping: [ "Rosshaar" ] },
    { key: "WV",     mapping: [ "Schurwolle" ] },
    { key: "SE",     mapping: [ "Seide (Maulbeerseide)" ] },
    { key: "SI",     mapping: [ "Sisal" ] },
    { key: "SN",     mapping: [ "Sunn" ] },
    { key: "CTA",    mapping: [ "Triacetat" ] },
    { key: "ST",     mapping: [ "Tussahseide" ] },
    { key: "JR",     mapping: [ "Urena" ] },
    { key: "WG",     mapping: [ "Vikunja" ] },
    { key: "CV",     mapping: [ "Viskose" ] },
    { key: "WO",     mapping: [ "Wolle (Schafwolle)" ] },
    { key: "WY",     mapping: [ "Yak" ] },
    { key: "HZ",     mapping: [ "Ziegenhaar" ] }
  ]
};
export let global_mc_list = list;