<svelte:options tag="mh-cc-product-slider" />

<script>
  import { beforeUpdate, onMount, tick, afterUpdate } from 'svelte';
  import Slider from './../Classes/Slider.class.js';

  export let campaign;
  export let brand;
  export let amount;
  export let eans;
  export let skus;

  export let url = '/scripts/modehaus/campaigns/getproducts.php';
  export let controls = 'true';
  //let identifier = campaign.identifier;

  const dbg = false;
  let isLoaded = null;
  let data = null;

  onMount(async () => {
    brand = brand;
    amount = amount;
    eans = eans;
    skus = skus;
    isLoaded = true;
    data = getProducts();
  });

  // Anzahl der anzuzeigenden Produkte pro Slide
  let items = 0;

  /**
   * die Mockupdaten werden generiert, wenn der Endpunkt nicht erreichbar ist oder irgendein anderer Fehler vorliegt.
   * Damit können weitere Entwicklungen und Ergänzungen einfach gestaltet werden und es kommt zu keinem Layoutbruch
   * sollte hier mal was nicht klappen.
   *
   */
  function createMockupData(amount) {

    const dummyImg = '/images/product_dummy.png';

    function createProducts(len) {
      let i = 0, resArr = [];
      for (i ; i<len ; i++) {
        resArr.push({
          entity_id: i,
          sku: 'dummy#sku___#color',
          remote_images: dummyImg,
          brand: brand,
          identifier: identifier || null,
          product_name: 'T-Shirt',
          price: '12.34',
          request_path: 'product/link/'+i
        });


      }
      return resArr;
    }

    let mockupData = {
      mockup: true,
      products: createProducts(amount)
    };

    return mockupData;
  }

  let slider;
  function initSlider(node) {
    if (dbg) console.log('init slider')
    slider = new Slider(node, {});
    slider.init();
  }

  function setupControls(node) {
    slider.controls = (controls === 'true') ? true : false;
    slider.setupControls(node);
    slider.listenResize();
  }


  async function getProducts() {

    const _formData = new FormData();
    _formData.append('brand', brand);
    _formData.append('amount', amount);
    _formData.append('eans', eans);
    _formData.append('skus', skus);

    const _opts = {
      method: 'post',
      mode: 'cors',
      body: _formData
    };

    await fetch(url, _opts)
      .then(res => {

        if (!res.ok && (res.status === 404 || res.status === 403) ) {
          if (dbg) console.warn('[Modehaus.de - mh-productslider]\nErforderlicher Endpunkt ist nicht erreichbar.\nMockup-Daten werden verwendet!');
          return createMockupData(amount);
        }
        else {
          return res.json();
        }
      })
      .then(result => {
        if (dbg) console.log(result);
        data = (result.products.length > 0) ? result.products : null;

      })
      .catch(err => {
        if (dbg) console.error(err);
      });
  }
</script>

<!-- Bestandteil des Modehaus.de-Netzwerkes -->
<div class="mh-productslider" style="width: 100%;">
{#if !isLoaded}
<div class="mh-loader-wrap">
    <div class="mh-loader"><div></div><div></div><div></div></div>
</div>
{:else}
    {#await data}
    {:then product}
    <ul class="mh_productsList" use:initSlider>
        {#each product as prd, idx}
        <li class="mh_productsItem">
        <a href="/{prd.request_path}" class="mh_productCard">
            <figure class="mh_image">
            <img src="{JSON.parse(prd.remote_images)[0].replace('.jpg', '_large.jpg')}" alt="">
            </figure>
            <div class="mh_brand">
            <small>{prd.brand}</small>
            </div>
            {#if prd.identifier}
            <div class="mh_identifier">
            {prd.identifier}
            </div>
            {/if}
            <div class="mh_name">
            <small>{prd.product_name}</small>
            </div>
            <div class="mh_prices">
            {prd.price} €
            </div>
        </a>
        </li>
        {/each}
    </ul>
    <div class="mh_controls" use:setupControls>
        <button class="active"></button>
    </div>
    {:catch error}
    {error.message}
    {/await}
{/if}
</div>


<style type="text/less">

</style>
