<svelte:options tag="mh-material-composition" />

<script>
  import { onMount, tick, afterUpdate } from 'svelte';
  import Transform from './TransformMaterialComposition.class.js';
  import { global_mc_list } from '../../GlobalDataLists/MaterialComposition/MaterialComposition_List.data.js';
  import ModehausLibInit from './../ModehausLibInit.svelte';

  const dbg = false;
  const langkey = 'de-DE';

  export let orig = 'Default';
  export let correct = 'false';
  export let deeplink = 'false';

  let parsed_mc;
  let checked_mc;
  let cleared_string;
  let orig_string;
  let cleared_parts;
  let translations = [];
  let status;


  afterUpdate(async () => {
    parsed_mc = decodeURIComponent(orig);
    translations = translations;
    checked_mc = new Transform(langkey, translations).checkForStatus(parsed_mc);
    cleared_string = checked_mc.cleared[0].cleared.checkedAndParsed.cleared;
    orig_string = checked_mc.cleared[0].cleared.checkedAndParsed.orig;
    cleared_parts = checked_mc.cleared[0].cleared.checkedAndParsed.parts_status;
    status = checked_mc.cleared[0].cleared.checkedAndParsed.status;
  });


  function onGettingMaterialTranslationList(event) {
    translations = event.detail.list;

    parsed_mc = decodeURIComponent(orig);
    // translations = translations;
    checked_mc = new Transform(langkey, translations).checkForStatus(parsed_mc);


    

    status = checked_mc.cleared[0].cleared.checkedAndParsed.status;
    cleared_string = checked_mc.cleared[0].cleared.checkedAndParsed.cleared;
    orig_string = checked_mc.cleared[0].cleared.checkedAndParsed.orig;

    cleared_parts = checked_mc.cleared[0].cleared.checkedAndParsed.parts_status;
  }

</script>

<!-- Bestandteil des Modehaus.de-Netzwerkes -->
<ModehausLibInit on:list={onGettingMaterialTranslationList}></ModehausLibInit>
<div class="mh-material-composition {(translations.length == 0) ? 'vanished' : 'appeared'}">
  {#if translations.length != 0}
    {#if dbg}
    <span class="origin">Im Shop:&nbsp;</span>
    {/if}
    {#if status==='error'}
      <span title="{parsed_mc}" class="cleared">{@html parsed_mc}</span>
    {:else}
      <span title="{parsed_mc}" class="cleared">{@html cleared_string}</span>
    {/if}
  {/if}
</div>


<style type="text/less">
.mh-material-composition {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-bottom: 0.4em;

  &.vanished {
    visibility: hidden;
  }

  &.appeared {
    visibility: visible;
  }

  span {
    

    &.origin {
      // display: none;
      text-align: right;
      background: #d3d2d2;
    }
  }
}
</style>