export default class Slider {

  constructor(element, props) {
    this.dbg = false;

    if (this.dbg) console.log(element, props);

    this._responsive = [
      { breakPoint: { width: 0, item: 1 } },
      { breakPoint: { width: 480, item: 3 } },
      { breakPoint: { width: 769, item: 4 } },
      { breakPoint: { width: 1025, item: 6 } },
      { breakPoint: { width: 1180, item: 8 } }
    ];

    this._column_gap = 24;
    this._jumpSlideWidth = 0;

    this._list = element;
    this._list_width = this._list.offsetWidth;

    // container for slide controls
    this._el_controls = this._list.parentNode.querySelector('.mh_controls');
    this._el_controls.innerHTML = '';

    this._coll_list_items = this._list.children;

    this._totalItems = this._coll_list_items.length;

    this._listensResize = null;

    if (this.dbg) console.log(this);
  }

  set controls(ctrl) {
    return this._controls = ctrl;
  }
  get controls() {
    return this._controls;
  }

  /** Ermittelt aufgrund der Eigenschaften des this._responsive-Arrays
   * die anzuzeigenden Produkte pro Slide.
   *
   * @returns {int} _items - Anzahl der Produkte pro Slide
   */
  getItemsPerSlide() {
    let _items;
    for (let i = 0 ; i < this._responsive.length ; i++) {
      if (this._list.parentNode.offsetWidth > this._responsive[i].breakPoint.width) {
        _items = this._responsive[i].breakPoint.item;
      }
    }
    return _items;
  }

  listenResize() {
    if (!this._listensResize) {
      this._listensResize = true;
      window.addEventListener('resize', () => {
        this.redraw();
      });
    }
  }

  redraw() {
    if (this.dbg) console.log('redraw');
    this.reset();
    this.init();
    this.setupControls(this._el_controls);
  }

  reset() {
    this._el_controls.children[0].click();
  }

  init() {
    let totalItemsWidth = 0;
    for (let i = 0 ; i < this._totalItems ; i++) {
      this._coll_list_items[i].style.width = this._list.parentNode.offsetWidth / this.getItemsPerSlide() + 'px';
      totalItemsWidth += this._list.parentNode.offsetWidth / this.getItemsPerSlide();
    }

    this._list.style.width = totalItemsWidth + 'px';
  }

  controlSlides(ele) {
    const list = ele.parentNode;
    const itms = list.children;

    let active;

    for (let i = 0 ; i < itms.length ; i++) {
      const itm = itms[i];
      if (itm.className == 'active') {
        itm.className = '';
      }
    }

    const clickedElement = ele;
    clickedElement.className = 'active';

    if (this.dbg) console.log('clickedElement', clickedElement);

    active = parseInt(clickedElement.getAttribute('data-idx'));
    this._jumpSlideWidth = this._list.parentNode.offsetWidth * active ;

    this._list.style.marginLeft = -1 * this._jumpSlideWidth + 'px';
  }

  setupControls(element) {

    const me = this;
    if (!this._controls) return;

    if (this.dbg) console.log('setupControls', element);
    element.innerHTML = '';

    const allSlides = Math.ceil(this._totalItems / this.getItemsPerSlide());

    for (let i = 0 ; i < allSlides ; i++) {
      const btn = document.createElement('button');
      btn.innerHTML = '&bull;';
      if (i === 0) {
        btn.className = 'active';
      }
      btn.setAttribute('data-idx', i);
      btn.addEventListener('click', (ev) => {
        me.controlSlides(ev.target);
      })
      element.appendChild(btn);
    }
  }
}
