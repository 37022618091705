<svelte:options tag="mh-campaign-linkloader" immutable={true} />

<script>
    import { onMount } from 'svelte';
    export let url = 'https://brandpages.modehaus.de/scripts/check_campaigns.php';
    export let brand = 'x';
    console.log('[first run - mh-campaign-linkloader]\nMarke: '+brand);

    async function getBrandsOrCampaignsLink(bb) {

        console.log('[fetch - mh-campaign-linkloader]\nMarke: '+bb);
        // const b = await brand;
        // console.log('[fetch - mh-campaign-linkloader]\nMarke: '+b);

        await fetch(url, {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({
                  brand:bb
                })
            })
            .then(res => {
                if (!res.ok && (res.status === 404 || res.status === 403) ) {
                    console.warn('[Modehaus.de - mh-campaign-linkloader]\nErforderlicher Endpunkt ist nicht erreichbar.\nMarke: '+brand);
                } else {
                    console.log('[1. Modehaus.de - mh-campaign-linkloader]\nMarke: '+brand);
                    return res.json()
                }
            })
            .then(result => {
                data = (result.pages.length > 0) ? result.pages[0] : null;
            })
            .catch(err => {
                console.error(err);
            });
    }

    onMount(async () => {
      const bb = await brand;
      data = getBrandsOrCampaignsLink(brand);
    });

    let data;
</script>

<!-- Bestandteil des Modehaus.de-Netzwerkes -->
<div class="mh-campaign-linkloader">
{#await data}
  <!-- getting data -->
{:then link}
  {#if link}
  <a class="btn" href="{link.brandpage_url}">
    <span>
      Entdecke {link.title}
    </span>
  </a>
  {/if}
{:catch error}
<!-- {error.message} -->
{/await}
</div>


<style type="text/less">
.mh-campaign-linkloader {
  .btn {
    display: inline-block;
    width: auto;
    padding: 0.3em 0.7em;
    background: rgba(255,255,255,0.6);
    box-shadow: 0px 0px 4px #ccc;
    border-radius: 2px;
    text-decoration: none;
    color: #333;

    span {
      display: inline-block;
      padding-top: 3px;
      transition: transform 200ms ease-in;
    }

    &:hover {
      box-shadow: 0px 0px 8px #ccc;
      span {
        transform: scale(1.04);
      }
    }
  }
}
</style>