<svelte:options tag="mh-cc-pages-overview" immutable={true} />

<script>
  import { onMount, tick, afterUpdate } from 'svelte';
  //import App from '../../../svelte.blueprints/src/App.svelte';
  import InitBrandpages from './Inits/InitBrandpages.svelte';
  import ProductSlider from './Partials/ProductSlider.svelte';

  const dbg = false;
  const langkey = 'de-DE';

  export let disturber_bg = "#c3c2c2";
  export let disturber_font = "#000";
  export let stock_label = "*";
  export let allowed_brands = [];

  let pages = [];
  let renderPage = false;
  let fullPage = {};
  let pageBrand = "";

  afterUpdate(async () => {
    pages = pages;
    renderPage = renderPage;
    fullPage = fullPage;
  });

  function onGettingPagesOverview(event) {
    allowed_brands = stock_label.toLowerCase().split("|");
    // console.log(allowed_brands);

    pages = event.detail.pages;
    // console.log(pages);

    pages.forEach(page => {
        if (allowed_brands.includes(page.brand.toLowerCase())) {
            page.show = true;
        } else {
            page.show = false;
        }
    });

    if (pages.length === 1) {
        renderPage = true;
        fullPage = pages[0];
        fullPage.dataSrc = JSON.parse(fullPage.dataSrc);
        pageBrand = fullPage.brand;
    }
  }

  function jumpToPage() {
    const _target_url = this.getAttribute('data-target');
    if (_target_url) {
      location.href = _target_url;
    }
  }

</script>

<!-- Bestandteil des Modehaus.de-Netzwerkes -->
<InitBrandpages on:pages={onGettingPagesOverview}></InitBrandpages>
<div class="mh-cc-pages-overview">
  {#if !renderPage}
  <ul class="mh-pages-list">
    {#each pages as page}
        {#if page.show}
        <li class="mh-page-item" data-brand="{page.brand}">
        <figure class="mh-pagecontent">
            <img src="{page.image}" alt="{page.name}">
            <figcaption data-target="?id={page.pageid}" on:click={jumpToPage} style="background-color: {disturber_bg}; color: {disturber_font};">
            {page.name}
            </figcaption>
        </figure>
        </li>
        {/if}
    {/each}
  </ul>
  {:else}
  <div class="mh-cc-page-wrapper">
  {#each fullPage.dataSrc as row}
  <div
    class="mh-cc-row-wrap"
    style="margin-bottom: {row.properties.marginBottom}px; margin-top: {row.properties.marginTop}px;">

    {#each row.contentElements as ce}
        <div class="mh-cc-row-element {ce.type}" style="text-align: {ce.properties.align}; height: {(ce.properties.height == 'auto' || ce.type === 'product') ? 'auto' : ce.properties.height + 'px'};">
            {#if ce.type == 'logo'}
            <img src="{ce.properties.logo_src}" style="width: {ce.properties.width}px;">
            {:else if ce.type == 'product'}

            <ProductSlider {...ce.properties}></ProductSlider>

            {:else if ce.type == 'image'}
            <div class="mh-ce-type-image" style="height: {row.properties.rowHeight}px; background-image: url({ce.properties.img_src}); background-size:cover;">
            </div>
            {:else if ce.type == 'divider'}
            <div class="mh-ce-type-divider" style="height: {row.properties.rowHeight}px;">
                <hr class="{ce.properties.styletype}" style="border-color: {ce.properties.color};">
            </div>
            {:else if ce.type == 'typo'}
            <article class="mh-ce-type-typo" >
                <header>
                <h3 style="font-size: {ce.properties.headline_size}; text-align: {ce.properties.headline_align};">
                    {ce.properties.headline}
                </h3>
                </header>
                <div style="font-size: {ce.properties.text_size}; text-align: {ce.properties.text_align};">
                {ce.properties.text}
                </div>
            </article>
            {/if}
        </div>
        {/each}
    </div>
    {/each}
  </div>
  <div style="text-align:center;"><a href="/catalogsearch/result/?q={pageBrand}" style="text-decoration: none; color: inherit; display:inline-block; padding: 2px; border:1px solid rgb(235, 235, 235); background-color: #fafafa;">Alle Artikel von {pageBrand} anzeigen</a></div>
  {/if}
</div>


<style type="text/less">
  .mh_controls {

    text-align: center;

    button {
      border: none;
      background: none;
      color: #ccc;
      font-size: 3em;
      transition: color 400ms ease-in-out;

      &.active {
        color: #000;
      }
    }
  }

.mh-cc-pages-overview {

  .mh-pages-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;

    .mh-page-item {

      .mh-pagecontent {
        position: relative;
        display: block;
        width: 160px;

        img {
          width: 100%;
        }

        figcaption {
          position: absolute;
          width: calc(124px + 1.4em);
          text-align: right;
          font-weight: bold;
          text-shadow: -1px -1px 0px rgba(0,0,0,0.4);
          letter-spacing: 0.5px;
          right: -1.4em;
          bottom: 2.3em;
          padding: 0.6em 0.4em;
          border-radius: 0.2em;
          box-shadow: 2px 2px 8px rgba(0,0,0,0.6);
          cursor: pointer;
          user-select: none;
          transition: all 200ms ease-in;

          &:hover {
            transform: scale(1.1);
            box-shadow: 4px 4px 12px rgba(0,0,0,0.6);
          }
        }
      }
    }
  }
}

.mh-cc-page-wrapper {
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
}

.mh-cc-row-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;

    .mh-cc-row-element {
        width: 100%;

        &.divider {

            hr {
                box-shadow: none;
                background: none;
                border-style: solid;
                &.default {
                    border-width: 2px;
                }
                &.bold {
                    border-width: 4px;
                }
            }
        }
        &.image {

            .mh-ce-type-image {
                background-repeat: no-repeat;
                background-size: auto;
                background-position: center;
            }
        }
    }
}

.mh-loader-wrap {
    text-align: center;
}

.mh-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.mh-loader div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #b3b2b2;
  animation: mh-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.mh-loader div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.mh-loader div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.mh-loader div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes mh-loader {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}


.mh-ce-type-typo {
    padding: 0 1em;
}


.mh-productslider {
  width: 100%;
  overflow: hidden;

  .mh_productsList {
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100%;
    transition: margin 200ms ease-in-out;

    & > * {
      box-sizing: border-box;
    }

    &:after {
      content: "";
      clear: both;
      display: table;
    }

    .mh_productsItem {
      float: left;

      .mh_productCard {
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0.5em;
        padding: 1em;
        background-color: white;
        border: 1px solid #ccc;
        color: #131212;
        text-decoration: none;

        .mh_image {
          padding: 0;
          margin: 0;

          img {
            width: 100%;
          }
        }
      }
    }
  }

  .mh_controls {
    text-align: center;
    button {
      border: none;
      background: none;
      color: #ccc;
      font-size: 3em;
      transition: color 400ms ease-in-out;

      &.active {
        color: #000;
      }
    }
  }
}
</style>
