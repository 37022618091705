export default class Transform {
  
  constructor(lang, translation_list) {
    this.dbg = false;
    this.lang_key = lang;

    this.acrMap = translation_list;

    this._multiline_delimiter            = new RegExp(/^.*$/gm);

    this._delimiter                      = new RegExp(/,|\||\/|;/g);
    this._materialFormat                 = new RegExp(/^[A-Za-z]?\:?[\d]*%\s[A-Za-z]*$/g);
    this._materialFormat_spcAfterDigit   = new RegExp(/^[A-Za-z]?\:?[\d]*\s%\s[A-Za-z]*$/g);
    this._materialFormat_nospace         = new RegExp(/^[A-Za-z]?\:?[\d]*%[A-Za-z]*$/g);

    this._material_prefix                = new RegExp(/^\D+(\s+\D+|:?)/g);
  }


  splitMultilines(str) {
    /* split at typical delimiter */
    let delimiter_split = str.match(this._multiline_delimiter);
    let parts_arr = [];
    for (let i = 0 ; i < delimiter_split.length ; i++) {
      let part = delimiter_split[i];

      if (part !== '') {
        // Freizeichen am Anfang und Ende müssen weg
        part = part.trim();      
        parts_arr.push(part);
      }
    }
    return parts_arr;
  }

  splitString(str) {
    /* split at typical delimiter */
    let delimiter_split = str.split(this._delimiter);

    let parts_arr = [];
    for (let i = 0 ; i < delimiter_split.length ; i++) {
      
      let part = delimiter_split[i];

      // Freizeichen am Anfang und Ende müssen weg
      part = part.trim();      
      parts_arr.push(part);
    }

    return parts_arr;
  }

  checkAgainstList(mat, percent, isParsable) {
    if (this.dbg) console.group(`checkAgainstList ${mat}`);


    console.log(`checkAgainstList ${mat} isParsable -> ${isParsable}`);

    let cleared = {
      hadAcronym: false,
      hadFullword: false,
      percent: percent,
      value: ''
    };


    let i = 0;
    const iLen = this.acrMap.length;

    for (i ; i<iLen ; i++) {
      const _matEntry = this.acrMap[i];
      // 1. check for key-replacement
      const _acr = new RegExp('\\b' + _matEntry.key + '\\b', 'g');

      if (mat.match(_acr)) {
        cleared.hadAcronym = true;
        cleared.value = mat.replace(_acr, _matEntry.mapping[0]);
      }
      else {
        // 2. check if mappings (aka translations) could be replaced
        let isInArray = _matEntry.mapping.filter((str) => {
          const _re = new RegExp('\\b' + str.toLowerCase() + '\\b');
          if (mat.toLowerCase().match(_re)) {
            if (this.dbg) console.log(`matching listwords`, str, mat)
            cleared.hadFullword = true;
            cleared.value = str;
          }
        });
      }

    }
    if (!cleared.hadAcronym && !cleared.hadFullword) {
      console.log('nichts passt');
      cleared.value = mat;
    }
  
    if (percent) {
      cleared.combined = percent + ' ' + cleared.value;
    }
    
    if (this.dbg) console.groupEnd();
    return cleared;
  }

  checkPart(part) {
    if (this.dbg) console.group('checkPart', part);

    let res = {
      hadAcronyms: false,
      hadFull: false,
      isDigit: false,
      orig_part: part,
      cleared_part: null
    };

    let percent_split = part.split('%');
    if (percent_split && part.match(this._materialFormat_nospace)) {
      part = percent_split.join('% ');
    }

    // Jetzt müssen wir ermitteln, ob alles hinter dem % Zeichen
    // irgendeinem Part der Übersetzungstabelle entspricht
    const _splitAtPercentAgain = part.split(/%\s/);
    if (_splitAtPercentAgain && typeof _splitAtPercentAgain[1] != 'undefined') {
      part = _splitAtPercentAgain[1];
    }

    let i = 0;
    const iLen = this.acrMap.length;

    for (i ; i<iLen ; i++) {
      const _matEntry = this.acrMap[i];
      // 1. check for key-replacement
      // const _acr = new RegExp('\b' + _matEntry.key + '\b', 'g');
      const _acr = new RegExp('\\b' + _matEntry.key + '\\b', 'g');
      if (part.match(_acr)) {
        res.hadAcronyms = true;
        part = part.replace(_acr, _matEntry.mapping[0]);
        if (this.dbg) console.log(part)
      }
      else {
        // 2. check if mappings (aka translations) could be replaced
        let m = 0;
        const mLen = _matEntry.mapping.length;

        if (_matEntry.mapping.includes(part)) {
          res.hadFull = true;
          part = part.replace(part, _matEntry.mapping[0]);
        }
      }
    }

    // 3. Jetzt brauchen wir die Prozentangaben zurück
    if (this.dbg) console.log('_splitAtPercentAgain', _splitAtPercentAgain);
    if (_splitAtPercentAgain && typeof _splitAtPercentAgain[0] != 'undefined') {
      
      _splitAtPercentAgain[0] = parseInt(_splitAtPercentAgain[0]);

      // if (_splitAtPercentAgain[0].match(/^[0-9]*$/g)) {
        part = _splitAtPercentAgain[0] + '% ' + part;
      // }


    }
    if (this.dbg) console.log('part after list check::', part);

    res.cleared_part = part;
    
    if (this.dbg) console.groupEnd();
    return res;
  }



  parseFullLine(line) {
    if (this.dbg) console.group(`parseFullLine ${line}`);

    let line_res = {

    };

    // check for Material Prefixes (Obermaterial:, Innenfutter etc)
    // and save in parsed array
    if (line.match(this._material_prefix)) {
      let __prefix = line.match(this._material_prefix)[0];


      // replace prefix in line
      line_res.part2parse = line.replace(__prefix, '');
      line_res.res_prefix = __prefix.replace(/\s*$/g, '');
      line_res.res_prefix = line_res.res_prefix.replace(/:/g, '');
      //line_res.res_prefix = __prefix.trim();
    }
    else {
      line_res.part2parse = line;
      line_res.res_prefix = null;
    }

    // Ab hier arbeiten wir mit _parsed-Parts weiter
    line_res.checkedAndParsed = this.checkAndParse(line_res.part2parse);

    if (this.dbg) console.groupEnd();
    return line_res;
  }




  checkAndParse(mc) {
    if (this.dbg) console.group('checkAndParse', mc);

        let res = {
          orig: mc,
          cleared: null,
          status: null
        }

        let parts_array = [];    // Laufzeitarray mit den Werten 0 oder 1
        let parts_status = [];   // Array, in denen die Parts mit ihrem Korrekturstatus gesammelt werden
        let parts_toModify= [];  // die modifizierten Parts einer Material-Komposition
        let _part_prefix = '';   // like Obermaterial, Innenfutter etc
        
        let i = 0;
        const _arr = this.splitString(mc);
        const iLen = _arr.length;
        for (i ; i<iLen ; i++) {
          let part = _arr[i];

          const checked = this.checkPart(part);
          let newPart = (checked.cleared_part) ? checked.cleared_part : checked.orig_part;

          if (newPart.match(this._materialFormat)) {
            parts_toModify.push(newPart);
            parts_array.push(1);
            parts_status.push({ status: 'ok', part: newPart });
          }
          else {
            if (newPart.match(this._materialFormat_nospace)) {
              let percent_split = newPart.split('%');
              newPart = percent_split.join('% ');
              parts_toModify.push(newPart);
              parts_array.push(1);
              parts_status.push({ status: 'ok', part: newPart });
            }
            else {
              parts_toModify.push(newPart);
              parts_array.push(0);
              parts_status.push({ status: 'edit', part: newPart });
            }
          }
        }

        if (this.dbg) console.info(parts_status);

        // Zähle nun zusammen
        const sum = parts_array.reduce(function(a, b){return a+b;})
        if (sum === parts_array.length) {
          res.status = 'ok';
        }
        if (sum < parts_array.length && sum > 0) {
          res.status = 'edit';
        }
        if (sum === 0) {
          res.status = 'error';
        }

        res.cleared = _part_prefix + parts_toModify.join(', ');

        let j = 0;
        const jLen = parts_status.length;
        let _counter = []
        for (j ; j<jLen ; j++) {
          const _part_status = (parts_status[j].status === 'ok') ? 0 : 1;
          _counter.push(_part_status);
        }

        // Wenn kein einziger Part korrigiert oder erkannt werden konnte, dann
        // setze den Status jedes Parts auf "error"!
        const _status_sum = _counter.reduce(function(a, b){return a+b;});
        if (_status_sum === parts_status.length) {
          let k = 0;
          for (k ; k<jLen ; k++) {
            parts_status[k].status = 'error';
          }
        }

        res.parts_status = parts_status;

        if (this.dbg) console.groupEnd();
        return res;
  }


  checkForStatus(mc) {
    if (this.dbg) console.group('checkForStatus', mc);

        let res = {
          cleared: null
        }

        let lines_array = [];            // Array mit jeder einzelnen Zeile
        let lines_parsed = [];           // Hilfsarray zum Ablegen der Prefixes and parts


        // Multiline prüfen
        if (mc.match(this._multiline_delimiter)) {
          lines_array = this.splitMultilines(mc);
        }
        else {
          lines_array.push(mc);
        }

        let lines_idx = 0;
        const lines_length = lines_array.length;
        for (lines_idx ; lines_idx<lines_length ; lines_idx++) {
          const _line = lines_array[lines_idx];
          let _parsed = {
            hasSum: false,
            orig: _line
          };

          _parsed.cleared = this.parseFullLine(_parsed.orig);

          // Prefix wieder drandübeln
          if (_parsed.cleared.res_prefix) {
            _parsed.cleared.checkedAndParsed.cleared = _parsed.cleared.res_prefix + ': ' + _parsed.cleared.checkedAndParsed.cleared;
          }

          lines_parsed.push(_parsed)
        }
        if (this.dbg) console.log('--------------------------------')
        if (this.dbg) console.log('lines_array PARSED', lines_parsed);

        res.cleared = lines_parsed;


        if (this.dbg) console.groupEnd();
        return res;

  }
}