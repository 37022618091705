<svelte:options tag="mh-material-composition-edit" />

<script>
  import { setContext, getContext,onMount, tick, afterUpdate } from 'svelte';
  import Transform from './TransformMaterialComposition.class.js';
  import { global_mc_i18n, global_mc_list } from '../../GlobalDataLists/MaterialComposition/MaterialComposition_List.data.js';
  import ModehausLibInit from './../ModehausLibInit.svelte';

  const dbg = false;
  const langkey = 'de-DE';

  export let orig;
  export let sku;
  export let update_shop;
  export let update_pool;

  let parsed_mc;
  let checked_mc;
  let cleared_string;
  let orig_string;
  let cleared_parts;
  let translations = [];
  let status;

  afterUpdate(async () => {
    parsed_mc = decodeURIComponent(orig);
    translations = translations;
    checked_mc = new Transform(langkey, translations).checkForStatus(parsed_mc);
    cleared_string = checked_mc.cleared[0].cleared.checkedAndParsed.cleared;
    orig_string = checked_mc.cleared[0].cleared.checkedAndParsed.orig;
    cleared_parts = checked_mc.cleared[0].cleared.checkedAndParsed.parts_status;
    status = checked_mc.cleared[0].cleared.checkedAndParsed.status;
  });

  function handleConfirmChange() {
    
    const mockup = {
      "action":"scripts/modehaus/campaigns/update_material_composition.php",
      "method":"updateMaterialComposition",
      "POST":{
        "sku": "NOTVALID",
        "update_shop":this.elements.update_shop.value,
        "update_pool":this.elements.update_pool.value,
        "material_composition": this.elements.new_material.value,
        "composition_list": JSON.stringify(global_mc_list)
      },
      "updated_value": this.elements.new_material.value,
      "status": true,
      "message": "Successful"
    };

    function getMockup() {
      return mockup;
    }

    const _formData = new FormData();
    _formData.append('method', 'updateMaterialComposition');
    _formData.append('composition_list', JSON.stringify(global_mc_list));
    _formData.append('sku', this.elements.sku.value);
    _formData.append('update_shop', this.elements.update_shop.value);
    _formData.append('update_pool', this.elements.update_pool.value);
    _formData.append('material_composition', this.elements.new_material.value);

    const _opts = {
      method: 'post',
      mode: 'cors',
      body: _formData
    };

    fetch('/scripts/materials/common/update_material_composition.php', _opts)
      .then(res => {
        if (!res.ok && (res.status === 404 || res.status === 403) ) {
          console.warn('[Modehaus.de - mh-material-composition-edit]\nErforderlicher Endpunkt ist nicht erreichbar.\nMockup-Daten werden verwendet!');
          return getMockup();
        }
        else {
          if (dbg) console.log('res json received', res);
          return res.json()
        }
      })
      .then(result => {
        if (dbg) console.log('result received', result);
        orig = encodeURIComponent(result.updated_value);
      })
      .catch(err => {
        if (dbg) console.error(err);
      });
  }

  function onGettingMaterialTranslationList(event) {
    translations = event.detail.list;

    parsed_mc = decodeURIComponent(orig);
    // translations = translations;
    checked_mc = new Transform(langkey, translations).checkForStatus(parsed_mc);

    status = checked_mc.cleared[0].cleared.checkedAndParsed.status;
    cleared_string = checked_mc.cleared[0].cleared.checkedAndParsed.cleared;
    orig_string = checked_mc.cleared[0].cleared.checkedAndParsed.orig;

    cleared_parts = checked_mc.cleared[0].cleared.checkedAndParsed.parts_status;
    
  }
</script>

<!-- Bestandteil des Modehaus.de-Netzwerkes -->
<ModehausLibInit on:list={onGettingMaterialTranslationList}></ModehausLibInit>
<div data-material-status="{status}" class="mh-material-composition-edit {(translations.length == 0) ? 'vanished' : 'appeared'}">
  <table>
    <caption class="{status}">
      <div class="{status}"></div>
      <div class="mh_title">{sku}
         <!-- - <a href="{deeplink}">Artikel im Shop</a> -->
      </div>
      <div class="mh_notices">
        {#if status==='ok'}
        <small><em>{@html global_mc_i18n[langkey].mc_edit.t_N_status_ok}</em></small>
        {:else if status==='edit'}
        <small><em>{@html global_mc_i18n[langkey].mc_edit.t_N_status_edit}</em></small>
        {:else if status==='error'}
        <small><em>{@html global_mc_i18n[langkey].mc_edit.t_N_status_error}</em></small>
        {/if}
      </div>
    </caption>
    <tr>
      <td>
          <small class="mh_label">{@html global_mc_i18n[langkey].mc_edit.t_N_from_db}</small><br>
          {parsed_mc}
      </td>
    </tr>
    <tr>
      <td class="mh_partsOutput">
        <small class="mh_label">{@html global_mc_i18n[langkey].mc_edit.t_N_auto_correct}</small><br>
        {#if cleared_parts}
          {#each cleared_parts as part}
            {#if part.status==='ok'}
              <span title="{global_mc_i18n[langkey].mc_edit.t_N_hint_part_ok}" class="{part.status}">{part.part}</span>
            {:else}
              <span title="{global_mc_i18n[langkey].mc_edit.t_N_hint_part_edit}" class="{part.status}">{part.part}</span>
            {/if}
          {/each}
        {/if}
      </td>
    </tr>
    <tr>
      <td>
        <blockquote>
          {@html global_mc_i18n[langkey].mc_edit.t_N_formatDesc}
        </blockquote>
      </td>
    </tr>
    <tr>
      <td>
        <small class="mh_label">{@html global_mc_i18n[langkey].mc_edit.t_N_correct_manually}</small><br>
        <form on:submit|preventDefault={handleConfirmChange}>
          <input name="sku" type="hidden" value="{sku}">

          {#if status==='error'}
            <textarea name="new_material">{parsed_mc}</textarea>
          {:else}
            <textarea name="new_material">{cleared_string}</textarea>
          {/if}
          <label style="display: none;">
            <span>Im <strong>Shop</strong> aktualisieren</span>
            <input type="checkbox" name="update_shop" checked>
          </label>
          <label style="display: none;">
            <span>Im <strong>Artikelpool</strong> aktualisieren</span>
            <input type="checkbox" name="update_pool" checked>
          </label>
          <button class="mh-global-btn" type="submit">
            {@html global_mc_i18n[langkey].mc_edit.t_N_btn_sendMc}
          </button>
        </form>
      </td>
    </tr>
    <tr>
      <td>
        <blockquote>
          {@html global_mc_i18n[langkey].mc_edit.t_N_footnotes}
        </blockquote>
      </td>
    </tr>
  </table>
</div>


<style type="text/less">
@import '../../LESS/mh-global-declarations';
.mh-material-composition-edit {
  @mh-accent-01: #83B817; /* green */
  @mh-accent-02: #C81E82; /* pink */

  @clr-ok: #81c784;
  @clr-warn: #ffb74d;
  @clr-error: #e57373;
  @clr-table-border: transparent;

  @glb_padding: 0.5em 0.8em;
  @glb_padding_half: 0.2em 0.4em;
  @glb_bg_color: #f3f2f2;

  margin-bottom: 2em;
  background: @glb_bg_color;

  &.vanished {
    visibility: hidden;
  }

  &.appeared {
    visibility: visible;
  }

  &[data-material-status="ok"] {
    
    tr {
      display: none;
    }
  }

  abbr {
    cursor: help;
  }

  .mh_label {
    color: @mh-accent-02;
    font-size: 65%;
    font-weight: bold;
    text-transform: uppercase;
  }

  .mh_title {
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
    font-size: 120%;
  }

  .mh_notices {
    font-weight: normal;
    margin: 0.2em 0;
    small {
      padding: 0.6em;
      border-radius: 0.3em;
      display: inline-block;
      background: rgba(255,255,255,0.6);
      box-shadow: 2px 2px 4px rgba(0,0,0,0.3);
    } 
  }

  table {
    width: 100%;
    border-collapse: collapse;

    caption {
      font-weight: bold;
      text-align: left;
      padding: @glb_padding;
      border-width: 1px;
      border-style: solid;
      border-color: @clr-table-border;

      &.ok {
        background-color: @clr-ok;
      }
      &.edit {
        background-color: @clr-warn;
      }
      &.error {
        background-color: @clr-error;
      }
    }

    tr {

      td {
        border-width: 1px;
        border-style: solid;
        border-color: @clr-table-border;
        padding: @glb_padding;
        vertical-align: bottom;
        blockquote {
          font-size: 80%;
          padding: 0;
          margin: 0;
        }

        textarea {
          width: 100%;
          min-height: 80px;
        }

        &.mh_partsOutput {

          span {
            position: relative;
            display: inline-block;
            padding: 0.2em 0.4em 0 0.4em;
            margin-right: 0.4em;
            border-style: dashed;
            border-width: 1px;
            border-bottom-width: 2px;
            // padding-left: 1.6em;

            &:before {
              display: none;
              content: '';
              position: absolute;
              width: 1em;
              height: 1em;
              left: 0.2em; 
              color: #ccc;             
              background-repeat: no-repeat;
              background-size: cover;
              opacity: 0.1;
            }

            &.ok {
              border-color: @clr-ok;
              background: lighten(@clr-ok, 30);
              &:before {
                color: @clr-ok;
                background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgaWQ9InN2ZzgiCiAgIHZlcnNpb249IjEuMSIKICAgdmlld0JveD0iMCAwIDMzLjg2NjY2NiAzMy44NjY2NjYiCiAgIGhlaWdodD0iMTI4IgogICB3aWR0aD0iMTI4Ij4KICA8ZGVmcwogICAgIGlkPSJkZWZzMiIgLz4KICA8bWV0YWRhdGEKICAgICBpZD0ibWV0YWRhdGE1Ij4KICAgIDxyZGY6UkRGPgogICAgICA8Y2M6V29yawogICAgICAgICByZGY6YWJvdXQ9IiI+CiAgICAgICAgPGRjOmZvcm1hdD5pbWFnZS9zdmcreG1sPC9kYzpmb3JtYXQ+CiAgICAgICAgPGRjOnR5cGUKICAgICAgICAgICByZGY6cmVzb3VyY2U9Imh0dHA6Ly9wdXJsLm9yZy9kYy9kY21pdHlwZS9TdGlsbEltYWdlIiAvPgogICAgICAgIDxkYzp0aXRsZT48L2RjOnRpdGxlPgogICAgICA8L2NjOldvcms+CiAgICA8L3JkZjpSREY+CiAgPC9tZXRhZGF0YT4KICA8ZwogICAgIGlkPSJsYXllcjEiPgogICAgPGcKICAgICAgIHRyYW5zZm9ybT0ic2NhbGUoMC4wNjYxNDU4MykiCiAgICAgICBpZD0iZzg5OSI+CiAgICAgIDxnCiAgICAgICAgIGlkPSJpY29tb29uLWlnbm9yZSIgLz4KICAgICAgPHBhdGgKICAgICAgICAgZD0iTSAyNTYsMCBDIDExNC42MTUsMCAwLDExNC42MTUgMCwyNTYgMCwzOTcuMzg1IDExNC42MTUsNTEyIDI1Niw1MTIgMzk3LjM4NSw1MTIgNTEyLDM5Ny4zODUgNTEyLDI1NiA1MTIsMTE0LjYxNSAzOTcuMzg1LDAgMjU2LDAgWiBtIC00OCwzODUgLTkwLC0xMDcgMzMsLTMzIDU3LDU2IDE2NSwtMTQ0IDIzLDIzIHoiCiAgICAgICAgIGlkPSJwYXRoODg3IiAvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg==);
              }
            }
            &.edit {
              border-color: @clr-warn;
              background: lighten(@clr-warn, 30);
              &:before {
                color: @clr-warn;
                background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgaWQ9InN2ZzgiCiAgIHZlcnNpb249IjEuMSIKICAgdmlld0JveD0iMCAwIDMzLjg2NjY2NiAzMy44NjY2NjYiCiAgIGhlaWdodD0iMTI4IgogICB3aWR0aD0iMTI4Ij4KICA8ZGVmcwogICAgIGlkPSJkZWZzMiIgLz4KICA8bWV0YWRhdGEKICAgICBpZD0ibWV0YWRhdGE1Ij4KICAgIDxyZGY6UkRGPgogICAgICA8Y2M6V29yawogICAgICAgICByZGY6YWJvdXQ9IiI+CiAgICAgICAgPGRjOmZvcm1hdD5pbWFnZS9zdmcreG1sPC9kYzpmb3JtYXQ+CiAgICAgICAgPGRjOnR5cGUKICAgICAgICAgICByZGY6cmVzb3VyY2U9Imh0dHA6Ly9wdXJsLm9yZy9kYy9kY21pdHlwZS9TdGlsbEltYWdlIiAvPgogICAgICAgIDxkYzp0aXRsZT48L2RjOnRpdGxlPgogICAgICA8L2NjOldvcms+CiAgICA8L3JkZjpSREY+CiAgPC9tZXRhZGF0YT4KICA8ZwogICAgIGlkPSJsYXllcjEiPgogICAgPGcKICAgICAgIHRyYW5zZm9ybT0ibWF0cml4KDAuMDY2MTQ1OCwwLDAsMC4wNjYxNDU4LDEuMTYwNTAwOGUtNSw4LjJlLTYpIgogICAgICAgaWQ9Imc4ODIiPgogICAgICA8ZwogICAgICAgICBpZD0iaWNvbW9vbi1pZ25vcmUiIC8+CiAgICAgIDxwYXRoCiAgICAgICAgIGQ9Im0gNTA0Ljk3OCw0NTcuODggdiAwIEwgMjg2LjQ0MSwyMi4zMjQgQyAyNzguMDcsNy40NDEgMjY3LjAzNSwwIDI1NiwwIDI0NC45NjUsMCAyMzMuOTMsNy40NDEgMjI1LjU1OCwyMi4zMjQgTCA3LjAyMSw0NTcuODggQyAtOS43MjIsNDg3LjY0NiA0LjUyMSw1MTIgMzguNjczLDUxMiBoIDQzNC42NTQgYyAzNC4xNTEsMCA0OC4zOTYsLTI0LjM1NCAzMS42NTEsLTU0LjEyIHogTSAyNTYsNDQ4IGMgLTE3LjY3MywwIC0zMiwtMTQuMzI3IC0zMiwtMzIgMCwtMTcuNjczIDE0LjMyNywtMzIgMzIsLTMyIDE3LjY3NCwwIDMyLDE0LjMyNiAzMiwzMiAwLDE3LjY3NCAtMTQuMzI2LDMyIC0zMiwzMiB6IG0gMzIsLTEyOCBjIDAsMTcuNjczIC0xNC4zMjcsMzIgLTMyLDMyIC0xNy42NzMsMCAtMzIsLTE0LjMyNyAtMzIsLTMyIHYgLTk2IGMgMCwtMTcuNjczIDE0LjMyNywtMzIgMzIsLTMyIDE3LjY3MywwIDMyLDE0LjMyNyAzMiwzMiB6IgogICAgICAgICBpZD0icGF0aDg3MCIgLz4KICAgIDwvZz4KICA8L2c+Cjwvc3ZnPgo=);
              }
            }
            &.error {
              border-color: @clr-error;
              background: lighten(@clr-error, 30);
              &::before {
                color: @clr-error;
                background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgd2lkdGg9IjEyOCIKICAgaGVpZ2h0PSIxMjgiCiAgIHZpZXdCb3g9IjAgMCAzMy44NjY2NjYgMzMuODY2NjY2IgogICB2ZXJzaW9uPSIxLjEiCiAgIGlkPSJzdmc4Ij4KICA8ZGVmcwogICAgIGlkPSJkZWZzMiIgLz4KICA8bWV0YWRhdGEKICAgICBpZD0ibWV0YWRhdGE1Ij4KICAgIDxyZGY6UkRGPgogICAgICA8Y2M6V29yawogICAgICAgICByZGY6YWJvdXQ9IiI+CiAgICAgICAgPGRjOmZvcm1hdD5pbWFnZS9zdmcreG1sPC9kYzpmb3JtYXQ+CiAgICAgICAgPGRjOnR5cGUKICAgICAgICAgICByZGY6cmVzb3VyY2U9Imh0dHA6Ly9wdXJsLm9yZy9kYy9kY21pdHlwZS9TdGlsbEltYWdlIiAvPgogICAgICAgIDxkYzp0aXRsZT48L2RjOnRpdGxlPgogICAgICA8L2NjOldvcms+CiAgICA8L3JkZjpSREY+CiAgPC9tZXRhZGF0YT4KICA8ZwogICAgIGlkPSJsYXllcjEiPgogICAgPGcKICAgICAgIGlkPSJnODY1IgogICAgICAgdHJhbnNmb3JtPSJzY2FsZSgwLjA2NjE0NTgzKSI+CiAgICAgIDxnCiAgICAgICAgIGlkPSJpY29tb29uLWlnbm9yZSIgLz4KICAgICAgPHBhdGgKICAgICAgICAgaWQ9InBhdGg4NTMiCiAgICAgICAgIGQ9Ik0gNTEyLDM2OCAzNjgsMCBIIDE0NCBMIDAsMTQ0IFYgMzY4IEwgMTQ0LDUxMiBIIDM2OCBMIDUxMiwzNjggViAxNDQgTCAzNjgsMCBaIG0gLTIyNCw0OCBoIC02NCB2IC02NCBoIDY0IHogbSAwLC0xMjggSCAyMjQgViA5NiBoIDY0IHoiIC8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K);
              }
            }
          }
        }

        &:first-child {
          width: 75%;
        }
      }
    }
  }
}
</style>