<svelte:options tag="mh-material-composition-process-all" />

<script>
  import { onMount, tick, afterUpdate } from 'svelte';
  import Transform from './TransformMaterialComposition.class.js';
  import { global_mc_i18n, global_mc_list } from '../../GlobalDataLists/MaterialComposition/MaterialComposition_List.data.js';
  import ModehausLibInit from './../ModehausLibInit.svelte';


  const dbg = true;
  const langkey = 'de-DE';

  let total = 0;
  let processBar = 0;
  let counter_articles_ok = 0;
  let counter_articles_edit = 0;
  let counter_articles_error = 0;
  let messageContainer;
  let translations;

  afterUpdate(async () => {
    total = total;  
    translations = translations;
    counter_articles_ok = counter_articles_ok;
    counter_articles_edit = counter_articles_edit;
    counter_articles_error = counter_articles_error;
    processBar = processBar;
  });

  function updateProgressBar() {
    const processed = counter_articles_ok + counter_articles_edit + counter_articles_error;
    processBar = processed * 100 / total;
  }

  function handleProcessAll() {

    // 1. reset Progressbar
    processBar = 0;

    // 2. reset counters
    counter_articles_ok = 0;
    counter_articles_edit = 0;
    counter_articles_error = 0;

    // messages DOM_Element
    let msg = this.elements[0].querySelector('.mh_processMessages');
    
    const mockup = {
      "action":"scripts/modehaus/campaigns/update_material_composition.php",
      "method":"getAllArticles",
      "POST":{
        "method": "getAllArticles",
        "composition_list": JSON.stringify(global_mc_list.materials)
      },
      "list": []
    };

    function getMockup() {
      // mockup.articles;

      const materials = [
        "Obermaterial: 70% Modal, 30% Polyester",
        "Duftrichtung: duftet wirklich gut, wirklich gut, gut gut gut; Kopfnote: aromatisch",
        "Obermaterial: 100% Baumwolle (cotton), Füllung: 55% Hanf, test",
        "Obermaterial: 70% Modal, 30% Polyester",
        "Obermaterial: 100% Baumwolle (cotton), Füllung: 55% Hanf, test",
        "Obermaterial: 11% Polyester, 3% Elasthan",
        "Obermaterial: 70% Modal, 30% Polyester",
        "Obermaterial: 11% Polyester, 3% Elasthan",
        "Obermaterial: 80% Viskose, 17% Polyester, 3% Elasthan; Füllung: 44% Manila",
        "Obermaterial: 100% Leder",
        "Obermaterial: 90% Polyamid (Perlon®, Nylon®), 10% Polyurethan",
        "Obermaterial: 100% Leder",
        "100% Baumwolle (cotton)",
        "Obermaterial: 96% Viskose, 4% Elasthan",
        "Obermaterial: 60% Baumwolle (engl.: cotton), 40% Polyester",
        "Obermaterial: 90% Polyamid (Perlon®, Nylon®), 10% Polyurethan",
        "52% Polyester, 43% Viskose, 5% Elasthan",
        "70% Polyester, 26% Viskose, 4% Lyocell"
      ];


      for (let i=0 ; i < 50; i++) {
        const _article = { "sku": "NOTVALID", "material_composition": materials[[Math.floor(Math.random() * materials.length)]] };
        mockup.list.push(_article);
      }
      return mockup;
    }

    function getUpdateMockup() {
      return {
        action: "scripts/modehaus/campaigns/update_material_composition.php",
        method: "checkAndUpdateArticles",
        status: true,
        message: "Successfull"
      }
    }

    const _formData = new FormData();
    _formData.append('method', 'getAllArticles');
    _formData.append('composition_list', JSON.stringify(translations));

    const _opts = {
      method: 'post',
      mode: 'cors',
      body: _formData
    };

    fetch('/scripts/materials/common/update_material_composition.php', _opts)
      .then(res => {
        if (!res.ok && (res.status === 404 || res.status === 403) ) {
          console.warn('[Modehaus.de - mh-material-composition-process-all]\nErforderlicher Endpunkt ist nicht erreichbar.\nMockup-Daten werden verwendet!');
          return getMockup();
        }
        else {
          if (dbg) console.log('res json received', res);
          return res.json()
        }
      })
      .then(result => {
        if (dbg) console.log('result received', result);

        const _req_processArticle = new FormData();
        _req_processArticle.append('method', 'checkAndUpdateArticles');

        const _opts = {
          method: 'post',
          mode: 'cors',
          body: _req_processArticle
        };

        // set the total amount
        total = result.list.length;
        // show messages
        msg.style.visibility = 'visible';

        let i = 0;
        const iLen = result.list.length;
        console.log(result.list);


        for ( i ; i < iLen ; i++) {
          const _itm = result.list[i];
          const checked_mc = new Transform(langkey, translations).checkForStatus(_itm.material_composition);
          _req_processArticle.append('sku', _itm.sku);
          _req_processArticle.append('material_composition', checked_mc.cleared[0].cleared.checkedAndParsed.cleared);

          if (checked_mc.cleared[0].cleared.checkedAndParsed.status === 'ok') {
            fetch('/scripts/materials/common/update_material_composition.php', _opts)
              .then(res => {
                if (!res.ok && (res.status === 404 || res.status === 403) ) {
                  console.warn('[Modehaus.de - mh-material-composition-process-all]\nErforderlicher Endpunkt ist nicht erreichbar.\nMockup-Daten werden verwendet!');
                  const mockupResponse = getUpdateMockup();
                  return mockupResponse;
                }
                else {
                  if (dbg) console.log('res json received', res);
                  return res.json()
                }
              })
              .then(result => {
                if (dbg) console.log('result received', result);
                counter_articles_ok++;
                updateProgressBar();
              })
              .catch(err => {
                if (dbg) console.error(err);
              });
          }
          else {
            if (checked_mc.cleared[0].cleared.checkedAndParsed.status === 'edit') { 
              counter_articles_edit++; 
              updateProgressBar(); 
            }
            if (checked_mc.cleared[0].cleared.checkedAndParsed.status === 'error') {
              counter_articles_error++; 
              updateProgressBar(); 
            }
          }
        }
      })
      .catch(err => {
        if (dbg) console.error(err);
      });
  }

  function onGettingMaterialTranslationList(event) {
    translations = event.detail.list;
  }
</script>

<!-- Bestandteil des Modehaus.de-Netzwerkes -->
<ModehausLibInit on:list={onGettingMaterialTranslationList}></ModehausLibInit>
<div class="mh-material-composition-process-all">
  <form on:submit|preventDefault={handleProcessAll}>
    <fieldset>
      <legend>Korrigiere alle Materialzusammensetzungen</legend>
      <p class="mh_processMessages"><small>Es wurden <strong>{total} Artikel</strong> mit Materialzusammensetzungen im Pool gefunden.</small></p>
      <div>
        <small><strong>{counter_articles_ok} Artikel</strong> konnten korrigiert werden</small><br>
        <small><strong>{counter_articles_edit} Artikel</strong> haben Unregelmässigkeiten und wurden <em>nicht</em> verarbeitet.</small><br>
        <small><strong>{counter_articles_error} Artikel</strong> konnten <em>nicht</em> verarbeitet werden.</small>
      </div>
      <div>
        <button type="submit" class="mh-global-btn" name="MH_ProcessAll">Alle korrigieren</button>
      </div>
      <div class="mh_progress">
        <div class="mh_progressInner" style="width: {processBar}%;"></div>
      </div>
    </fieldset>
  </form>
</div>


<style type="text/less">
@import '../../LESS/mh-global-declarations';
.mh-material-composition-process-all {
  .mh_processMessages {
    visibility: hidden;
    transition: visibility 200ms ease-in;
  }

  .mh_progress {
    @heightandradius: 0.8em;

    position: relative;
    height: @heightandradius;
    margin: 0.4em 0;

    .mh_progressInner {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(90deg, #dd2c00 20%, #26c6da);
      transition: width 200ms ease-in;
    }
  }
}
</style>