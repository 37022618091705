<svelte:options tag="mh-material-composition-translations" />

<script>
  import { onMount, tick, afterUpdate } from 'svelte';
  import Transform from './TransformMaterialComposition.class.js';
  import { global_mc_i18n, global_mc_list } from '../../GlobalDataLists/MaterialComposition/MaterialComposition_List.data.js';
  import ModehausLibInit from './../ModehausLibInit.svelte';

  const dbg = false;
  let translations = [];

  function updateListonDB(list) {

    const _formData = new FormData();
    _formData.append('method', 'addTranslations');
    _formData.append('list', JSON.stringify(list)); 


    const _opts = {
      method: 'post',
      mode: 'cors',
      body: _formData
    };

    fetch("/scripts/materials/common/get_materials_list.php", _opts)
      .then(res => {
        if (!res.ok && (res.status === 404 || res.status === 403) ) {
          console.warn('[Modehaus.de - mh-material-composition-translations]\nErforderlicher Endpunkt ist nicht erreichbar.\nMockup-Daten werden verwendet!');
          //return getMockup();
        }
        else {
          if (dbg) console.log('res json received', res);
          return res.json()
        }
      })
      .then(result => {
        if (dbg) console.log('result received', result);
        if (result.status === 'ok') {
          translations = translations;
          localStorage.setItem('mhLists', JSON.stringify(translations));
        }
      })
      .catch(err => {
        if (dbg) console.error(err);
      });
  }

  function handleAddToList() {

    if (this.elements.new_translation.value === '') {
      return;
    }

    translations[this.elements.list_index.value].mapping.push(this.elements.new_translation.value);
    updateListonDB(translations);
    translations = translations;

  }

  function handleDeleteFromList() {
    const entry = this.innerHTML;
    const mappings = translations[parseInt(this.getAttribute('data-entry-idx'))].mapping;
    
    const onIndex = mappings.indexOf(entry);
    const new_mappings = mappings.splice(onIndex, 1);
    translations[parseInt(this.getAttribute('data-entry-idx'))].mapping = mappings;
    updateListonDB(translations);
    translations = translations;
  }

  afterUpdate(async () => {
    translations = translations;
  });


  function onGettingMaterialTranslationList(event) {
    translations = event.detail.list;
  }

 //translations = global_mc_list.materials;
</script>
<!-- Bestandteil des Modehaus.de-Netzwerkes -->
<ModehausLibInit on:list={onGettingMaterialTranslationList}></ModehausLibInit>
<div class="mh-material-composition-translations">
  {#each translations as trans, idx}
  <form on:submit|preventDefault={handleAddToList}  autocomplete="off">
    
    <div class="mh-input-wrap">
      <input type="hidden" name="list_index" value={idx}>
      <input type="text" name="new_translation" autocomplete="off">
    </div>

    <div class="mh-input-wrap">
      <button class="mh-global-btn" type="submit">Übersetzen zu</button>
    </div>


    <div class="mh-input-wrap">
      {#each trans.mapping as mapping, index}
        {#if index == 0}        
          <span class="mh-translation-root">
            {trans.mapping[0]} <small>default</small>
          </span>
        {:else}
          <button title="Löschen" class="mh-label-button" data-entry-idx="{idx}" on:click|preventDefault|stopPropagation={handleDeleteFromList}>{mapping}</button>
        
        {/if}
      {/each}
    </div>
  </form>
  {/each}
</div>


<style type="text/less">
@import '../../LESS/mh-global-declarations';
.mh-material-composition-translations {
  form {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 1em 0;

    .mh-input-wrap {
      margin: 0 1em;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      input {
        background: @clr-blue-grey-lighten-5;
        border: 1px solid @clr-blue-grey-lighten-4;
        border-radius: 0.3rem;
        padding: 0.5rem 0.8rem 0.4rem 0.8rem;
      }

      .mh-translation-root {
        background: @clr-blue-grey-lighten-5;
        border-radius: 0.3rem;
        border: 1px solid @clr-blue-grey-lighten-4;
        padding: 0.5rem 0.8rem 0.4rem 0.8rem;
      }

      .mh-label-button {
        vertical-align: middle;
        position: relative;
        display: inline-block;
        border-radius: 0.3rem;
        background: @clr-blue-grey-lighten-5;
        border: 1px solid @clr-blue-grey-lighten-4;
        padding: 0.5rem 1.8rem 0.4rem 0.8rem;

        &:after {
          position: absolute;
          content: 'X';
          top: 6px;
          right: 6px;
          border: 1px solid red;
          color: red;
          background: lighten(red, 40);
          border-radius: 50%;
          width: 1rem;
          height: 1rem;
          line-height: 1.3;
        };
      }
    }
  }
}
</style>