<svelte:options tag="mh-lib-init" />
<script>
  import { onMount, tick, afterUpdate, createEventDispatcher } from 'svelte';
  import { global_mc_list } from '../GlobalDataLists/MaterialComposition/MaterialComposition_List.data.js';
  import { global_config } from '../GlobalConfig/global.config.js';

  const dbg = false;

  const dispatch = createEventDispatcher();

  afterUpdate(async () => {
    
  });


  async function getTranslationListFromDb() {

    const _formData = new FormData();
    _formData.append('method', 'getTranslationListFromDb');

    const _opts = {
      method: 'post',
      mode: 'cors',
      body: _formData
    };

    await fetch('/scripts/materials/common/get_materials_list.php', _opts)
      .then(res => {
        if (!res.ok && (res.status === 404 || res.status === 403 || res.status === 302) ) {
          if (dbg) console.warn('[Modehaus.de - mh-material-composition-translations]\nErforderlicher Endpunkt ist nicht erreichbar.\nMockup-Daten werden verwendet!');
          return {
            action: '[debug] GetMaterialsListFromDB',
            method: '[debug] getMockup',
            list: global_mc_list.materials
          };
        }
        else {
          if (dbg) console.log('res json received', res);
          return res.json()
        }
      })
      .then(result => {
        if (dbg) console.log('result received', result);
        dispatch('list', {list: result.list});  
      })
      .catch(err => {
        dispatch('list', {list: global_mc_list.materials});        
      });
  }

  let translationList = getTranslationListFromDb();
</script>

<!-- Bestandteil des Modehaus.de-Netzwerkes -->
<div class="mh-lib-init"></div>


<style type="text/less">
.mh-lib-init {

}
</style>